var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('h1',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$lang("template.title.usedDashboards"))+" "+_vm._s(_vm.$route.query.templateName)+" ")]),(_vm.isUsageInInaccessibleDashboards)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","type":"warning","outlined":""}},[_vm._v(" "+_vm._s(_vm.$lang("template.usageInInaccessibleDashboards"))+" ")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-4 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.usedDashboards,"items-per-page":50,"footer-props":{
      itemsPerPageOptions: [25, 50, 100, -1],
    },"loading":_vm.isUsedDashboardsLoading},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"item.accessType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang(("accessType." + (item.accessType.toLowerCase()))))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"bottom":"","icon":"","x-small":"","disabled":!_vm.isSuperAdmin && item.owner !== _vm.currentUser.username},on:{"click":function($event){$event.stopPropagation();return _vm.routeToEditDashboardPage(item)}}},on),[_c('v-icon',[_vm._v(" mdi-view-dashboard-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("dashboard.edit")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"bottom":"","icon":"","x-small":"","disabled":!_vm.isSuperAdmin && item.owner !== _vm.currentUser.username},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("dashboard.delete")))])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$lang( "dashboard.deleteDialogHeading", (_vm.deletedDashboard && _vm.deletedDashboard.name) || "" )))]),_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.$lang( "dashboard.deleteDialogContent", (_vm.deletedDashboard && _vm.deletedDashboard.name) || "" ))+" ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hideDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$lang("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.isDashboardDeleting,"loading":_vm.isDashboardDeleting},on:{"click":_vm.deleteDashboard}},[_vm._v(" "+_vm._s(_vm.$lang("delete"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }