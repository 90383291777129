





































































































import { Component, Vue } from "vue-property-decorator";

import { CurrentUserModel } from "@/shared/models";

import UsedDashboardModel, {
  DashboardModel,
} from "@/templates/models/UsedDashboardModel";

@Component
export default class UsedDashboardsView extends Vue {
  readonly headers = [
    {
      text: this.$lang("template.name"),
      value: "name",
      width: "25%",
    },
    {
      text: this.$lang("template.owner"),
      value: "owner",
      width: "15%",
    },
    {
      text: this.$lang("accessType.title"),
      value: "accessType",
      width: "12%",
    },
    {
      text: this.$lang("segment.actions.title"),
      value: "action",
      width: "10%",
      align: "end",
      sortable: false,
    },
  ];

  isDeleteDialogVisible = false;
  isDashboardDeleting = false;
  deletedDashboard: DashboardModel | null = null;

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isUsedDashboardsLoading(): boolean {
    return this.$store.state.templateStore.isUsedDashboardsLoading;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get isSuperAdmin(): boolean {
    return this.$store.getters.isSuperAdmin;
  }

  get usedDashboards(): UsedDashboardModel {
    return this.$store.state.templateStore.usedDashboards?.dashboards ?? [];
  }

  get isUsageInInaccessibleDashboards(): boolean {
    return !!this.$store.state.templateStore.usedDashboards
      ?.usageInInaccessibleDashboards;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("template.title.usedDashboards")
    );
    this.$store.dispatch(
      "loadUsedDashboardsByTemplateId",
      this.$route.params.templateId
    );
  }

  handleClickByTableRow(item: DashboardModel) {
    this.$router.push({
      name: "dashboard_view",
      params: {
        applicationId: this.applicationId,
        dashboardId: String(item.id),
      },
    });
  }

  routeToEditDashboardPage(item: DashboardModel) {
    const route = this.$router.resolve({
      name: "dashboard_update",
      params: {
        id: this.applicationId,
        dashboardId: String(item.id),
      },
      query: {
        templateId: this.$route.params.templateId,
      },
    });

    window.open(route.href, "_blank");
  }

  showDeleteDialog(item: DashboardModel) {
    this.deletedDashboard = item;
    this.isDeleteDialogVisible = true;
  }

  hideDeleteDialog() {
    this.isDeleteDialogVisible = false;
  }

  async deleteDashboard() {
    if (this.deletedDashboard) {
      this.isDashboardDeleting = true;
      await this.$store.dispatch("deleteDashboard", {
        id: this.deletedDashboard.id,
        name: this.deletedDashboard.name,
      });
      await this.$store.dispatch(
        "loadUsedDashboardsByTemplateId",
        this.$route.params.templateId
      );
      this.isDashboardDeleting = false;
      this.hideDeleteDialog();
    }
  }
}
